import * as React from 'react';

type Props = {
  white?: boolean;
}
export const KeyIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = (props) => (
  <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
    <path
      d="M34 0c-8.82 0-16 7.176-16 16 0 1.96.383 3.824 1.031 5.563L.281 40.28 0 40.594v6.812l.281.313 2 2 .313.281h6.812l.313-.281 3-3 .281-.313V44h2.406l.313-.281 3-3 .281-.313V39h1.406l.313-.281 2-2 .281-.313V35h1.406l.313-.281 3.718-3.75C30.177 31.617 32.04 32 34 32c8.82 0 16-7.18 16-16 0-8.824-7.18-16-16-16zm0 2c7.738 0 14 6.258 14 14 0 7.738-6.262 14-14 14-1.922 0-3.742-.363-5.406-1.063l-.032-.03C23.535 26.78 20 21.804 20 16c0-7.742 6.262-14 14-14zm0 3a10.976 10.976 0 00-7.781 3.219l-.719.719.719.687 14.156 14.156.688.719.718-.719c4.29-4.285 4.29-11.277 0-15.562A10.976 10.976 0 0034 5zm0 2c2.3 0 4.613.863 6.375 2.625 3.273 3.273 3.375 8.371.563 11.906L28.468 9.063C30.103 7.766 32.024 7 34 7zM19.875 23.531a16.101 16.101 0 006.594 6.594L23.562 33H21v2.563L19.562 37H17v2.563L14.562 42H11v3.563L8.562 48H3.439l-1-1L19 30.437a1 1 0 00-.938-1.718c-.19.043-.363.14-.5.281L2 44.594v-3.157z"
    />
  </svg>
);
