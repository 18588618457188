import * as React from 'react';

type Props = {
  white?: boolean;
}
export const PasswordIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = (props) => (
  <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
    <path
      d="M25 1.219c-7.168 0-13 5.832-13 13V20H8c-1.645 0-3 1.355-3 3v24c0 1.645 1.355 3 3 3h34c1.645 0 3-1.355 3-3V23c0-1.645-1.355-3-3-3h-4v-5.781c0-7.168-5.832-13-13-13zm0 2c6.09 0 11 4.91 11 11V20H14v-5.781c0-6.09 4.91-11 11-11zM8 22h34c.563 0 1 .438 1 1v24c0 .563-.438 1-1 1H8c-.563 0-1-.438-1-1V23c0-.563.438-1 1-1zm5 11a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm8 0a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm8 0a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm8 0a1.999 1.999 0 100 4 1.999 1.999 0 100-4z"
    />
  </svg>
);
