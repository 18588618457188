import * as React from 'react';

type Props = {
  white?: boolean;
}
export const SecuritySslIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = (props) => (
  <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
    <path
      d="M25 0c-2.453 0-4.523 1.355-7.594 2.938-3.07 1.582-7.191 3.492-13.625 4.906L3 8.03v.781c0 15.32 5.371 25.56 10.75 31.907 5.379 6.347 10.844 8.875 10.844 8.875l.437.218.438-.218S47 38.469 47 8.812v-.78l-.781-.188C39.785 6.43 35.664 4.52 32.594 2.937 29.524 1.355 27.454 0 25 0zm0 2c1.645 0 3.55 1.105 6.688 2.719 2.98 1.531 7.171 3.41 13.25 4.843-.368 27.118-18.684 37.29-19.97 37.97-.62-.302-4.948-2.466-9.718-8.094C10.223 33.504 5.246 23.96 5.062 9.561c6.079-1.433 10.27-3.312 13.25-4.843C21.45 3.105 23.355 2 25 2zm-5.594 15.438c-2.14 0-3.343 1.402-3.343 3.187 0 1.242.882 2.402 2.156 3.219.933.648 1.5 1.176 1.5 2.093 0 .954-.63 1.594-1.75 1.594-.594 0-1.324-.215-1.75-.468l-.313 1.562c.356.238 1.211.469 2.063.469 2.058 0 3.531-1.285 3.531-3.344 0-1.223-.586-2.332-2.031-3.281-1.172-.746-1.625-1.18-1.625-2.063 0-.699.496-1.375 1.531-1.375.613 0 1.082.172 1.406.375l.313-1.562c-.305-.188-.957-.407-1.688-.407zm6.438 0c-2.14 0-3.344 1.402-3.344 3.187 0 1.242.883 2.402 2.156 3.219.934.648 1.5 1.176 1.5 2.093 0 .954-.597 1.594-1.718 1.594-.594 0-1.356-.215-1.782-.468l-.312 1.562c.355.238 1.21.469 2.062.469 2.059 0 3.532-1.285 3.532-3.344 0-1.223-.586-2.332-2.032-3.281-1.172-.746-1.625-1.18-1.625-2.063 0-.699.496-1.375 1.532-1.375.613 0 1.082.172 1.406.375l.343-1.562c-.304-.188-.988-.407-1.718-.407zm3.5.093V29h4.875v-1.531h-3.063V17.53z"
    />
  </svg>
);
