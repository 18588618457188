import * as React from 'react';

type Props = {
  white?: boolean;
}
export const HappyCloudIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = (props) => (
  <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
    <path
      d="M28.992 8.547c-5.215 0-9.61 3.113-11.73 7.523-1.133-.78-2.43-1.347-3.907-1.347-3.714 0-6.703 2.941-6.925 6.601C2.707 22.672 0 26.152 0 30.328 0 35.66 4.34 40 9.672 40h31.426C46.004 40 50 36.004 50 31.098c0-4.551-3.488-8.18-7.906-8.7.015-.234.047-.46.047-.703 0-7.25-5.899-13.148-13.149-13.148zm0 2c6.172 0 11.149 4.98 11.149 11.148 0 .457-.036.914-.094 1.383l-.14 1.121H41.097A6.886 6.886 0 0148 31.098 6.887 6.887 0 0141.098 38H9.672A7.655 7.655 0 012 30.328c0-3.535 2.387-6.488 5.625-7.383l.762-.21-.028-.786c-.004-.14-.011-.21-.011-.219a4.993 4.993 0 015.007-5.007c1.391 0 2.637.562 3.543 1.468L18 19.297l.547-1.465c1.57-4.246 5.637-7.285 10.445-7.285zM19 22a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm12 0a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm-15.031 6.98a.998.998 0 00-.723 1.676S18.98 35 25 35s9.754-4.344 9.754-4.344a1 1 0 10-1.508-1.313S30.055 33 25 33c-5.055 0-8.246-3.656-8.246-3.656a.983.983 0 00-.785-.364z"
    />
  </svg>
);
