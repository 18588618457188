import * as React from 'react';

type Props = {
  white?: boolean;
}
export const CardSecurityIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = (props) => (
  <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
    <path
      style={{
        lineHeight: 'normal',
        textIndent: 0,
        textAlign: 'start',
        textDecorationLine: 'none',
        textDecorationStyle: 'solid',
        textDecorationColor: '#000',
        textTransform: 'none',
        isolation: 'auto',
        mixBlendMode: 'normal',
      }}
      d="M7 9c-2.75 0-5 2.25-5 5v22c0 2.75 2.25 5 5 5h22.637a22.746 22.746 0 01-.67-2H7c-1.668 0-3-1.332-3-3V21h42V27.86c.562.231 1.21.462 2 .685V14c0-2.75-2.25-5-5-5H7zm0 2h36c1.668 0 3 1.332 3 3v2H4v-2c0-1.668 1.332-3 3-3zm2 13v2h16v-2H9zm31 3.9c-1.5 0-2.5.5-3.7 1.1-1.2.6-2.7 1.3-5.5 1.9l-.8.2v.8c0 13 9.2 17.7 9.5 17.9l.5.2.5-.2c.4-.2 9.5-5.3 9.5-17.9v-.8l-.8-.2c-2.8-.6-4.3-1.3-5.5-1.9-1.2-.6-2.2-1.1-3.7-1.1zm0 2c1.1 0 1.7.3 2.8.9 1.2.5 2.7 1.3 5.2 1.9-.3 9.3-6.3 13.9-8 15-1.8-1-7.7-5.3-8-15 2.6-.6 4.1-1.4 5.2-1.9 1.1-.6 1.7-.9 2.8-.9z"
      fontWeight={400}
      fontFamily="sans-serif"
      overflow="visible"
    />
  </svg>
);
