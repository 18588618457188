import React from 'react';
import { SubpageLayout } from '../components/SubpageLayout';
import { Seo } from '../components/Seo';
import { CardSecurityIcon } from '../components/Icons/CardSecurityIcon';
import { PasswordIcon } from '../components/Icons/PasswordIcon';
import { KeyIcon } from '../components/Icons/KeyIcon';
import { DataProtectionIcon } from '../components/Icons/DataProtectionIcon';
import { HappyCloudIcon } from '../components/Icons/HappyCloudIcon';
import { SecuritySslIcon } from '../components/Icons/SecuritySslIcon';

const data = [
  {
    id: '1',
    icon: <SecuritySslIcon width="85" height="85"/>,
    content: (
      <>
        Komunikacja między Twoją przeglądarką, a usługami <a href="https://app.fakturuj.to">FAKTURUJ.TO</a> odbywa się
        za pomocą protokołu <span className="font-bold">SSL</span>. Protokół ten zapewnia przekazywane Twoich danych w
        postaci zaszyfrowanej.</>
    ),
  },
  {
    id: '2',
    icon: <HappyCloudIcon width="85" height="85"/>,
    content: (
      <>
        Nasze rozwiązania <span className="font-bold">działają na chmurze zaufanych dostawców</span> (Microsoft Azure
        oraz MongoDB Atlas), gdzie prawidłowe działanie systemu jest <span className="font-bold"> monitorowane przez cały czas</span>.
      </>
    ),
  },
  {
    id: '3',
    icon: <PasswordIcon width="85" height="85"/>,
    content: (
      <>
        Zasoby aplikacji są dostępne <span className="font-bold">tylko dla zalogowanych użytkowników</span>, a dostęp do
        danych wymaga autoryzacji.
      </>
    ),
  },
  {
    id: '4',
    icon: <KeyIcon width="85" height="85"/>,
    content: (
      <>
        Rejestracja oraz logowanie odbywa się przy użyciu standardu <span className="font-bold">OAuth</span>, który
        umożliwia łatwe logowanie również za pomocą konta Google.
      </>
    ),
  },
  {
    id: '5',
    icon: <DataProtectionIcon width="85" height="85"/>,
    content: (
      <>
        Nasze bazy danych są zabezpieczone <span className="font-bold">firewallami</span>, a dostęp do nich jest
        ograniczony w niezbędnym zakresie.
      </>
    ),
  },
  {
    id: '6',
    icon: <CardSecurityIcon width="85" height="85"/>,
    content: (
      <>
        <span className="font-bold">Nigdy nie zapisujemy danych Twojej karty płatniczej</span>. Są one przechowywane w
        zaufanym przez Ciebie systemie płatności.
      </>
    ),
  },
];

const PrivacyPage = () => (
  <SubpageLayout title="Standardy Bezpieczeństwa">
    <Seo
      title="Standardy Bezpieczeństwa"
    />
    <div className='container mx-auto px-4'>
      <div className="flex flex-row flex-wrap pb-4 pb-0">
        {data.map((item) => (
          <div
            key={item.id}
            className="flex flex-col px-10 mb-10 lg:mb-12 lg:pb-2 w-full items-center justify-center lg:flex-row lg:w-1/2"
          >
            <div className="flex items-center flex-1 m-8 mt-0">
              {item.icon}
            </div>
            <div className="w-full mb-8">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  </SubpageLayout>
);

export default PrivacyPage;
